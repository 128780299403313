import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import "./textWithImage.css"

const TextWithImage = (props) => {
	return (
		<section className={`text-with-image ${props.data.theme}`}>
			<div className={`text-image-grid ${props.data.blockStyle}`}>
				<div className="text">
					<p>{props.data.text.text}</p>
					<a href={`/post/${props.data.link.slug}`}>{props.data.linkTitle}</a>
				</div>
				<div className="image">
					<GatsbyImage image={props.data.image.gatsbyImageData} alt={props.data.image.description} />
				</div>
			</div>
		</section>
		
	)
}

export default TextWithImage