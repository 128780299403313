import React from "react"
import "./hero.css"
import brownFlower1 from "../images/brownFlower1.svg"
import brownFlower2 from "../images/brownFlower2.svg"
import brownFlower3 from "../images/brownFlower3.svg"
import brownFlower4 from "../images/brownFlower4.svg"
import brownFlower5 from "../images/brownFlower5.svg"
import redFlower1 from "../images/redFlower1.svg"
import redFlower2 from "../images/redFlower2.svg"
import redFlower3 from "../images/redFlower3.svg"
import redFlower4 from "../images/redFlower4.svg"
import blueFlower2 from "../images/blueFlower2.svg"
import blueFlower3 from "../images/blueFlower3.svg"
import blueFlower4 from "../images/blueFlower4.svg"
import pinkFlower1 from "../images/pinkFlower1.svg"
import pinkFlower2 from "../images/pinkFlower2.svg"
import pinkFlower3 from "../images/pinkFlower3.svg"
import pinkFlower4 from "../images/pinkFlower4.svg"
import bang1 from "../images/bang1.svg"
import bang2 from "../images/bang2.svg"
import bang4 from "../images/bang4.svg"
import heart1 from "../images/heart1.svg"
import leaf1 from "../images/leaf1.svg"
import leaf2 from "../images/leaf2.svg"
import leaf3 from "../images/leaf3.svg"
import leaf4 from "../images/leaf4.svg"
import leaf5 from "../images/leaf5.svg"
import leaf6 from "../images/leaf6.svg"

const Hero = ({ title, subheading }) => {
  return (
    <header className="black">
      <div className="header-grid">
        <div className="flowers">
          <img
            className="flower flower1 animated slideInLeft faster"
            src={brownFlower1}
            alt=""
          />
          <img
            className="flower flower2 animated jackInTheBox delay-2s"
            src={redFlower2}
            alt=""
          />
          <img
            className="flower flower3 animated jackInTheBox delay-1s"
            src={redFlower1}
            alt=""
          />
          <img
            className="flower flower4 animated rotateIn delay-1s"
            src={blueFlower4}
            alt=""
          />
          <img
            className="flower flower5 animated jackInTheBox  "
            src={pinkFlower1}
            alt=""
          />
          <img
            className="flower flower6 animated bounceIn delay-3s"
            src={bang1}
            alt=""
          />
          <img
            className="flower flower7 animated rotateIn delay-2s"
            src={brownFlower2}
            alt=""
          />
          <img
            className="flower flower8 animated rotateIn delay-2s"
            src={blueFlower2}
            alt=""
          />
          <img
            className="flower flower9 animated fadeIn "
            src={heart1}
            alt=""
          />
        </div>
        <div className="title">
          <h1>{title}</h1>
          {subheading && <p className="subheading">{subheading}</p>}
        </div>
        <div className="flowers">
          <img
            className="flower flower10 animated jackInTheBox  faster"
            src={leaf1}
            alt=""
          />
          <img
            className="flower flower11 animated jackInTheBox "
            src={leaf2}
            alt=""
          />
          <img
            className="flower flower12 animated jackInTheBox  delay-2s"
            src={leaf3}
            alt=""
          />
          <img
            className="flower flower13 animated jackInTheBox  delay-1s"
            src={leaf4}
            alt=""
          />
          <img
            className="flower flower14 animated jackInTheBox  delay-2s"
            src={leaf5}
            alt=""
          />
          <img
            className="flower flower15 animated jackInTheBox  delay-1s"
            src={leaf6}
            alt=""
          />
          <img
            className="flower flower16 animated rotateIn delay-2s"
            src={pinkFlower2}
            alt=""
          />
          <img
            className="flower flower17 animated fadeIn faster"
            src={pinkFlower3}
            alt=""
          />
          <img
            className="flower flower18 animated slideInRight delay-1s"
            src={pinkFlower4}
            alt=""
          />
          <img
            className="flower flower19 animated jackInTheBox faster"
            src={brownFlower3}
            alt=""
          />
          <img
            className="flower flower20 animated rotateIn delay-2s"
            src={brownFlower4}
            alt=""
          />
          <img
            className="flower flower21 animated rotateIn delay-1s"
            src={blueFlower3}
            alt=""
          />
          <img
            className="flower flower22 animated fadeIn faster"
            src={redFlower3}
            alt=""
          />
          <img
            className="flower flower23 animated jackInTheBox delay-1s"
            src={redFlower4}
            alt=""
          />
          <img
            className="flower flower24 animated bounceIn delay-4s"
            src={bang2}
            alt=""
          />
        </div>
        <div className="flowers-mobile">
          {/* <!-- leaves --> */}
          <img
            className="flower flower10 animated jackInTheBox  faster"
            src={leaf1}
            alt=""
          />
          <img
            className="flower flower11 animated jackInTheBox "
            src={leaf2}
            alt=""
          />
          <img
            className="flower flower12 animated jackInTheBox  delay-2s"
            src={leaf3}
            alt=""
          />
          <img
            className="flower flower13 animated jackInTheBox  delay-1s"
            src={leaf4}
            alt=""
          />
          <img
            className="flower flower14 animated jackInTheBox  delay-2s"
            src={leaf5}
            alt=""
          />
          <img
            className="flower flower15 animated jackInTheBox  delay-1s"
            src={leaf6}
            alt=""
          />
          {/* <!-- end leaves --> */}
          <img
            className="flower flower2 animated jackInTheBox delay-2s"
            src={redFlower2}
            alt=""
          />
          <img
            className="flower flower3 animated jackInTheBox delay-1s"
            src={redFlower1}
            alt=""
          />
          <img
            className="flower flower5 animated jackInTheBox  "
            src={pinkFlower1}
            alt=""
          />
          <img
            className="flower flower6 animated bounceIn delay-3s"
            src={bang4}
            alt=""
          />
          <img
            className="flower flower8 animated rotateIn delay-2s"
            src={blueFlower2}
            alt=""
          />
          <img
            className="flower flower9 animated fadeIn "
            src={heart1}
            alt=""
          />
          <img
            className="flower flower16 animated rotateIn delay-2s"
            src={pinkFlower2}
            alt=""
          />
          <img
            className="flower flower17 animated fadeIn faster"
            src={pinkFlower3}
            alt=""
          />
          <img
            className="flower flower19 animated jackInTheBox faster"
            src={brownFlower3}
            alt=""
          />
          <img
            className="flower flower20 animated rotateIn delay-2s"
            src={brownFlower5}
            alt=""
          />
          <img
            className="flower flower21 animated rotateIn delay-1s"
            src={blueFlower3}
            alt=""
          />
          <img
            className="flower flower23 animated jackInTheBox delay-1s"
            src={redFlower4}
            alt=""
          />
          <img
            className="flower flower24 animated bounceIn delay-4s"
            src={bang2}
            alt=""
          />
        </div>
      </div>
    </header>
  )
}

export default Hero
