import React from "react"
import "./quoteText.css"

const QuoteText = (props) => {


	return (
		<section className={`quote-text ${props.theme}`}>
			<div className="quote-text-wrapper">
				<p>{props.quote}</p>
			</div>
		</section>
	)
}

export default QuoteText