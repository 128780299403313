import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import QuoteText from "./quoteText"
import TextWithImage from "./textWithImage"
import Hero from "./hero"
import Video from "./Video"
import RichTextContent from "./RichTextContent/RichTextContent"
import "./homePage.css"
import pinkBirdRight from "../images/pink-bird-right.svg"

const HomePage = () => (
  <StaticQuery
    query={graphql`
      query HomePageQuery {
        allContentfulHomePage {
          edges {
            node {
              title
              subheading
              pageBlocks {
                ... on ContentfulVideo {
                  id
                  title
                  internal {
                    type
                  }
                  videoFile {
                    file {
                      url
                    }
                  }
                  thumbnail {
                    file {
                      url
                    }
                  }
                }
                ... on ContentfulQuoteText {
                  id
                  quote
                  internal {
                    type
                  }
                  theme
                }
                ... on ContentfulTextWithImage {
                  id
                  internal {
                    type
                  }
                  image {
                    gatsbyImageData
                    description
                  }
                  linkTitle
                  link {
                    slug
                  }
                  text {
                    text
                  }
                  blockStyle
                  theme
                }
                ... on ContentfulPostPage {
                  internal {
                    type
                  }
                  id
                  __typename
                  title
                  postColor
                  content {
                    raw
                    references {
                      ... on ContentfulDefinition {
                        contentful_id
                        __typename
                        definingWord
                        definition {
                          raw
                        }
                      }
                      ... on ContentfulColorBlockedText {
                        contentful_id
                        __typename
                        content {
                          raw
                        }
                      }
                      ... on ContentfulTable {
                        contentful_id
                        __typename
                        title
                        table {
                          tableData
                        }
                      }
                      ... on ContentfulDetailedTable {
                        contentful_id
                        __typename
                        title
                        tableContents {
                          raw
                          references {
                            ... on ContentfulDefinition {
                              contentful_id
                              __typename
                              definingWord
                              definition {
                                raw
                              }
                            }
                          }
                        }
                      }
                      ... on ContentfulAsset {
                        contentful_id
                        __typename
                        gatsbyImageData
                        description
                      }
                      ... on ContentfulVideo {
                        contentful_id
                        __typename
                        title
                        videoFile {
                          file {
                            url
                          }
                        }
                        thumbnail {
                          file {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        contentfulSiteGroup {
          mainGroupItems {
            ... on ContentfulPrimaryMenuGroup {
              id
              __typename
              menuTitle
              slug
            }
            ... on ContentfulPostPage {
              id
              __typename
              title
              slug
            }
          }
        }
      }
    `}
    render={(data) => {
      // specific background colors based on entry titles in Contentful, fallback is a pink color
      const backgroundColors = {
        biomarkers: "orange",
        "mediation framework": "green",
        resiliency: "blue",
      }
      const homePage = data.allContentfulHomePage.edges[0].node
      const [featuredPost] = homePage.pageBlocks.filter(
        (block) => block.__typename === "ContentfulPostPage"
      )
      return (
        <div className="homepage-wrapper">
          <Hero title={homePage.title} subheading={homePage.subheading} />
          {data.allContentfulHomePage.edges[0].node.pageBlocks.map((block) => {
            switch (block.internal.type) {
              case "ContentfulVideo":
                return (
                  <Video
                    key={block.id}
                    url={block.videoFile.file.url}
                    thumbnail={block.thumbnail.file.url}
                    className={backgroundColors[block.title.toLowerCase()]}
                  />
                )
              case "ContentfulQuoteText":
                return (
                  <QuoteText
                    key={block.id}
                    quote={block.quote}
                    theme={block.theme}
                  />
                )
              case "ContentfulTextWithImage":
                return <TextWithImage key={block.id} data={block} />
              default:
                return
            }
          })}
          <section className="content">
            <div className="content-grid">
              <div className="sticky">
                <aside className="side-menu no-sub-menu">
                  <p className="primary-menu-title homepage">
                    Learn more about...
                  </p>
                  <nav className="side-nav">
                    <ul>
                      {data.contentfulSiteGroup.mainGroupItems.map(
                        (linkGroup) => (
                          <li key={linkGroup.id} className="sub-menu-link">
                            <Link
                              to={`${
                                linkGroup.__typename === "ContentfulPostPage"
                                  ? "/post"
                                  : ""
                              }/${linkGroup.slug}/`}
                            >
                              {linkGroup.menuTitle || linkGroup.title}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  </nav>
                </aside>
              </div>
              {featuredPost && (
                <div className="history">
                  <h2 className="title home-post-title">
                    {featuredPost.title}
                  </h2>
                  <RichTextContent content={featuredPost.content} />
                  <div className="bird-border">
                    <img src={pinkBirdRight} alt="" />
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      )
    }}
  />
)

export default HomePage
