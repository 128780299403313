import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HomePage from "../components/homePage";

const HomePageWrapper = () => (
  <Layout>
    <Seo title="Home" keywords={[`queer`, `care`, `stress`, "lgbtq2"]} />
    <div className="home">
      <HomePage />
    </div>
  </Layout>
) 
export default HomePageWrapper;
